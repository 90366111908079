import axios from 'axios'
import store from './store'
import router from './router'
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://liveschool-app.liveabc.com/api/v2' : 'https://livetalk-dev.liveabc.com/api/v2'
axios.defaults.timeout = 60000

let exclude = ['/login', '/register', '/courses/talk', '/courses/class', '/courses/teacher', '/teachers', '/videos', '/talks', '/news/unread_count']
axios.interceptors.request.use(request => {

  const token = localStorage.getItem('authorizedToken')
  const isExclude = exclude.some(v => request.url.includes(v)) // 是否包含不需要token API
  let authorized = store.state.auth.authorized

  if ((token && authorized) || (token && !isExclude && !authorized)) {
    // 登入&&token 或 未登入&&未包含指定path
    request.headers.common['Authorization'] = `Bearer ${token}`
  }
  axios.defaults.withCredentials = false

  return request
})

axios.interceptors.response.use(response => {
  if (response.data.error && response.data.error === 'TOKEN_EXPIRED') {
    console.log('TOKEN_EXPIRED')
    store.dispatch('refreshToken')
  }
  if (response.data.error && response.data.error === 'TOKEN_INVALID') {
    console.log('TOKEN_INVALID')
    store.dispatch('logout')
    router.push({ name: 'home' })
  }
  return response
}, error => {
  store.dispatch('isLoading', false)
  if (error.response) {
    // store.commit('setErrorMessage', error.response)
    let { status } = error.response
    if (status === 429) {
      // store.dispatch('updateAlert', { isAlert: true, isHead: true, alertMsg: '系統忙碌中，請稍後再試', type: 'alert' })
    }
  } else {
    // store.commit('setErrorMessage', error.message)
  }

  var originalRequest = error.config
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
    originalRequest._retry = true
    return axios.request(originalRequest)
  }

  return Promise.reject(error)
})
