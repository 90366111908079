import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue';
import '@/assets/css/tailwind.css';
import '@/assets/css/main.scss';
import '@/assets/css/checkbox.css';
import '@/assets/css/sweatalert.scss';

import '@/assets/css/popup.scss';
import '@/assets/css/radioBox.scss';
import './api'
import router from './router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2'
import axios from 'axios'
import 'sweetalert2/dist/sweetalert2.min.css'
import moment from 'moment'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

import VCalendar from 'v-calendar'

Vue.use(VCalendar)
Vue.use(VueSweetalert2)

import VueI18n from 'vue-i18n'
import VTooltip from 'v-tooltip'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.use(VTooltip)
Vue.use(VueI18n)
let locale = 'zh'

Vue.use(require('vue-moment'))
Vue.prototype.moment = moment

const i18n = new VueI18n({
  locale: locale,
  messages: {
    'zh': require('@/i18n/zh.json'),
    'en': require('@/i18n/en.json'),
    'cn': require('@/i18n/cn.json')
  }
})
Vue.config.lang = store.state.lang
// Object.keys(locale).forEach( (lang) => {
//   Vue.locale(lang, locale[lang])
// })

Vue.prototype.$http = axios

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

import Notifications from 'vue-notification'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: 'G-K2NXRVWZ8N' }
})

// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.component('v-select', vSelect)
Vue.use(Notifications)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')



