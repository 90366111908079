<template>
  <div id="app" class="mx-auto overflow-hidden m-0 p-0 scroll-bar">
    <notifications group="foo" position="bottom right" />
    <loading :active.sync="isLoading"></loading>
    <router-view></router-view>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { mapGetters } from 'vuex'
  export default {
    name: 'app',
    components: {
      Loading
    },
    data () {
      return {
        isOpenUser: false
      }
    },
    metaInfo: {
      title: 'LiveSchool遠距教學平台',
      titleTemplate: '%s · LiveSchool'
    },
    mounted () {
    },
    computed: {
      ...mapGetters([
        'userRole',
        'authorized',
        'isLoading'
      ])
    },
    methods: {
      outside () {
        this.isOpenUser = false
      },
      setRole (event) {
        this.$store.dispatch('setRole', event.target.value)
      }
    }
  }
</script>

<style>
</style>
